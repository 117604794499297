import React from 'react';
import Title from '../Title/Title';
import FeatureMatrix from './FeatureMatrix';
import PricingCardList from './PricingCardList';
import RequestDemo from '../RequestDemo';
import { cards, pricingPlans, featureMatrix } from './content';
import * as styles from './index.module.scss';

export default function PricingMain() {
  return (
    <main>
      <section>
        <PricingCardList cards={cards} />
      </section>
      <section>
        <Title
          className={styles.sectionTitle}
          size="m"
          title="Compare features"
        />
        <FeatureMatrix
          featureMatrix={featureMatrix}
          pricingPlans={pricingPlans}
        />
        <RequestDemo
          buttonText="Contact us"
          buttonProps={{
            className: styles.requestDemoBtn,
            type: 'orange-gradient',
          }}
        />
      </section>
    </main>
  );
}
