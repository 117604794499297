import React from 'react';
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import Main from '../components/Pricing';
import Footer from '../components/Footer/Footer';

export default function PricingPage() {
  return (
    <Layout title="Pricing">
      <Header>
        <PageTitle
          title="Iterative Studio Pricing"
          text="Collaborate across your ML teams and enable your data scientists with an easy-to-use interface. Our open-source tools are always free; Studio starts free and scales with your team."
        />
      </Header>
      <Main />
      <Footer />
    </Layout>
  );
}
