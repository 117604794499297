import React from 'react';
import PricingCard from './Card';
import { pricingCardList } from './index.module.scss';

export default function PricingCardList({ cards }) {
  return (
    <ul className={pricingCardList}>
      {cards.map((props, i) => (
        <PricingCard key={i} {...props} />
      ))}
    </ul>
  );
}
