import React from 'react';
import cn from 'classnames';
import * as styles from './index.module.scss';

function FeaturesHeaderRow({ title, pricingPlans }) {
  return (
    <tr className={cn('bg-gray-200 text-center font-bold', styles.headerRow)}>
      <td colSpan={3} className="w-2/5 text-left md:hidden">
        {title}
      </td>
      <td colSpan={1} className="w-2/5 text-left hidden md:table-cell">
        {title}
      </td>
      {pricingPlans.map(({ type }) => (
        <td className="w-1/5 hidden md:table-cell" key={type} />
      ))}
    </tr>
  );
}

export default FeaturesHeaderRow;
