import React from 'react';
import Title from '../../../Title/Title';
import * as styles from './index.module.scss';

export default function FeaturesHead({ pricingPlans }) {
  return (
    <thead>
      <tr className={styles.headRow}>
        <th />
        {pricingPlans.map(({ name, price }, i) => (
          <th key={i}>
            <Title title={name} size="s" />
            <p>{price}</p>
          </th>
        ))}
      </tr>
    </thead>
  );
}
