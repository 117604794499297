import React from 'react';
import cn from 'classnames';
import FeaturesHead from './FeaturesHead';
import FeaturesHeaderRow from './FeaturesHeaderRow';
import FeatureValue from './FeatureValue';
import Title from '../../Title/Title';
import * as styles from './index.module.scss';

const featureNameCellClasses = 'text-left align-top';

function FeatureMatrix({ featureMatrix, pricingPlans, className }) {
  return (
    <>
      <table className={cn('w-full', className, styles.table)}>
        <FeaturesHead pricingPlans={pricingPlans} />

        <tbody>
          {featureMatrix.map(({ title, features }, i) => (
            <>
              {i !== 0 && (
                <tr
                  className={cn(
                    'md:hidden text-center',
                    styles.tableRow,
                    styles.tableRow_header
                  )}
                >
                  {pricingPlans.map(({ name }) => (
                    <td className="w-4/12 text-center">
                      {<Title size="xs" title={name} />}
                    </td>
                  ))}
                </tr>
              )}
              <FeaturesHeaderRow pricingPlans={pricingPlans} title={title} />
              {features.map(({ name, plans }, index) => (
                <>
                  <tr className={cn('text-center md:hidden', styles.tableRow)}>
                    <td colSpan={3} className={featureNameCellClasses}>
                      {name}
                    </td>
                  </tr>
                  <tr
                    className={cn(
                      'md:hidden',
                      'text-center',
                      styles.tableRow,
                      index === features.length - 1 && styles.tableRow_footer
                    )}
                  >
                    {pricingPlans.map(({ type }) => (
                      <td className="w-4/12" key={type}>
                        <FeatureValue value={plans[type]} />
                      </td>
                    ))}
                  </tr>
                  <tr
                    className={cn(
                      'text-center',
                      'hidden md:table-row',
                      styles.tableRow,
                      index === features.length - 1 && styles.tableRow_footer
                    )}
                    key={index}
                  >
                    <td className={featureNameCellClasses}>{name}</td>
                    {pricingPlans.map(({ type }) => (
                      <td className="w-1/5" key={type}>
                        <FeatureValue value={plans[type]} />
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default FeatureMatrix;
