import React from 'react';
import Icon from '../../../Icon/Icon';
import checkImg from '../../../../images/icons/check.svg';
import rectImg from '../../../../images/icons/rect.svg';
import * as styles from './index.module.scss';

function FeatureValue({ value }) {
  if (typeof value === 'boolean') {
    return value ? (
      <Icon
        className={styles.icon}
        icon={checkImg}
        widthPixelSizes={[24, 32, 32]}
      />
    ) : (
      <Icon
        className={styles.icon}
        icon={rectImg}
        widthPixelSizes={[24, 32, 32]}
      />
    );
  }

  return <>{value}</>;
}

export default FeatureValue;
