import React from 'react';
import BaseLink from '../BaseLink/BaseLink';
import RequestDemo from '../RequestDemo';

export const cards = [
  {
    title: 'Free',
    price: <b>$0</b>,
    description: 'Free for small teams up to 2 members',
    buttonText: 'Get started',
    buttonLink: 'https://studio.iterative.ai/',
    list: [
      'Unlimited repositories',
      'Integration with popular Git providers',
      'Integration with on-premises GitLab',
      'Sharing projects',
      'Running experiments',
      'Plots visualization',
      'Data-centric comparison of experiments',
      'Integration with common cloud providers',
      'Granular Access Control',
      'Authentication via popular Git providers and emails',
    ],
  },
  {
    title: 'Teams',
    price: (
      <>
        Starts at <b>$40</b> per user monthly
      </>
    ),
    description:
      'Collaboration on machine learning projects for teams of any size',
    buttonText: 'Get started',
    buttonLink: 'https://studio.iterative.ai/',
    listTitle: 'Everything in Free Plan, plus:',
    list: ['Unlimited collaborators', 'Priority support'],
    subText: (
      <>
        <RequestDemo>Contact us</RequestDemo> to learn more about additional
        support and services options.
      </>
    ),
  },
  {
    title: 'Enterprise',
    price: <>Contact us for details</>,
    description:
      'For larger team collaboration and advanced enterprise requirements',
    buttonText: 'Contact us',
    buttonLink: '/request-demo',
    listTitle: 'Everything in Teams, plus:',
    list: [
      'Flexible deployment (our VPC, your VPC, on-premises)',
      'Dedicated ML solution architect and professional services',
    ],
    subText: (
      <>
        We offer discounts to active DVC/CML teams, nonprofits, and educational
        institutions for Enterprise plan. <RequestDemo>Reach out</RequestDemo>{' '}
        to learn more.
      </>
    ),
  },
];

export const featureMatrix = [
  {
    features: [
      {
        name: 'Integration with GitLab.com, GitHub.com, BitBucket.org',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Integration with on-premises GitLab',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: (
          <>
            Flexible deployment <br /> (our VPC, your VPC, on-premises)
          </>
        ),
        plans: {
          enterprise: true,
          free: false,
          team: false,
        },
      },
    ],
    title: 'Hosting and installation',
  },
  {
    features: [
      {
        name: 'Number of Git repositories that can be connected',
        plans: {
          enterprise: 'Unlimited',
          free: 'Unlimited',
          team: 'Unlimited',
        },
      },
      {
        name: 'Sharing projects with teams/public',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Running experiments',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Plots visualisation',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Data-centric comparison of experiments',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Integration with common cloud providers including AWS, GCP, Azure and Kubernetes',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
    ],
    title: 'Features',
  },
  {
    features: [
      {
        name: <BaseLink href="/model-registry">Model Registry</BaseLink>,
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Data Catalog for structured (csv, parquet) and unstructured (images, audio) data',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
    ],
    title: 'Upcoming',
  },
  {
    features: [
      {
        name: (
          <>
            Granular Access Control <br /> (Read, Write, Admin)
          </>
        ),
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Authentication via GitHub, GitLab and Bitbucket and email',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
    ],
    title: 'Security',
  },
  {
    features: [
      {
        name: 'Community support',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Email support',
        plans: {
          enterprise: true,
          free: true,
          team: true,
        },
      },
      {
        name: 'Priority support',
        plans: {
          enterprise: true,
          free: false,
          team: true,
        },
      },
      {
        name: 'Dedicated ML solution architect and professional services',
        plans: {
          enterprise: true,
          free: false,
          team: false,
        },
      },
    ],
    title: 'Support',
  },
];

export const pricingPlans = [
  { name: 'Free', price: 'Up to two users', type: 'free' },
  {
    name: 'Teams',
    price: 'Starts at $40 per user monthly',
    type: 'team',
  },
  {
    name: 'Enterprise',
    price: <RequestDemo>Contact us</RequestDemo>,
    type: 'enterprise',
  },
];
