import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../../Title/Title';
import ButtonLink from '../../../Button/ButtonLink';
import RequestDemo from '../../../RequestDemo';
import * as styles from './index.module.scss';

export default function PricingCard({
  title,
  price,
  description,
  buttonText,
  buttonLink,
  listTitle,
  list,
  subText,
}) {
  return (
    <li className={styles.pricingCard}>
      <Title
        className={styles.pricingCard__title}
        title={title}
        size="xs"
        sizeStyle="s"
      />
      <h5 className={styles.pricingCard__price}>{price}</h5>
      <p className={styles.pricingCard__text}>{description}</p>
      {buttonLink === '/request-demo' ? (
        <RequestDemo
          buttonText="Contact us"
          buttonProps={{
            className: styles.pricingCard__btn,
            type: 'orange-gradient',
          }}
        />
      ) : (
        <ButtonLink className={styles.pricingCard__btn} href={buttonLink}>
          {buttonText}
        </ButtonLink>
      )}
      <div className={styles.pricingCard__features}>
        {listTitle && (
          <p className={styles.pricingCard__featuresText}>{listTitle}</p>
        )}
        <ul className={styles.pricingCard__featuresList}>
          {list.map((text, i) => (
            <li key={i}>{text}</li>
          ))}
        </ul>
        {subText && (
          <p className={styles.pricingCard__featuresSubText}>{subText}</p>
        )}
      </div>
    </li>
  );
}

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.node,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  listTitle: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  subText: PropTypes.node,
};
